// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ---------- AUDIO RECORDER ---------- */
.audio-recorder-wrapper {
    padding: 1.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.audio-recorder-wrapper h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.audio-recorder-wrapper textarea {
    resize: none;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/AudioRecorder.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;IACI,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":["/* ---------- AUDIO RECORDER ---------- */\n.audio-recorder-wrapper {\n    padding: 1.5rem;\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);\n}\n\n.audio-recorder-wrapper h3 {\n    font-size: 1.25rem;\n    font-weight: 500;\n    margin-bottom: 1rem;\n}\n\n.audio-recorder-wrapper textarea {\n    resize: none;\n    padding: 1rem;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
