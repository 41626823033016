import React, { useState, useEffect } from 'react';
import AudioRecorder from '../components/AudioRecorder';
import { Container, Row, Col, Button, Collapse, Card } from 'react-bootstrap';
import './Dashboard.css'; // Import custom CSS for Dashboard

const Dashboard = ({ onLogout }) => {
    const [open, setOpen] = useState(false); // State to handle collapse
    const [htmlContent, setHtmlContent] = useState(''); // State to store the HTML content

    // Fetch the content of the HTML file when the component mounts
    useEffect(() => {
        fetch('/instructions.htm') // Assuming 'my.html' is in the public folder
            .then((response) => response.text())
            .then((data) => {
                setHtmlContent(data); // Set the HTML content
            })
            .catch((error) => console.error('Error loading HTML file:', error));
    }, []);

    return (
        <Container fluid className="dashboard-wrapper">
            <Row className="justify-content-between">
                <Col xs={12} md={8}>
                    <h1 className="dashboard-title text-center text-md-start">DCHC Dictation Portal</h1>
                </Col>
                <Col xs={12} md={4} className="text-md-end mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">
                    <Button variant="secondary" onClick={onLogout} className="btn-logout">
                        Logout
                    </Button>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col xs={12}>
                    <Collapse in={open}>
                        <div id="collapse-content">
                            <Card className="p-3 mb-3">
                                <div className="instructions-content">
                                    {/* Inject the HTML content dynamically */}
                                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                </div>
                            </Card>
                        </div>
                    </Collapse>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="collapse-content"
                        aria-expanded={open}
                        className="mb-3 w-100"
                    >
                        {open ? 'Hide Instructions' : 'Show Instructions'}
                    </Button>

                    <AudioRecorder />
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
