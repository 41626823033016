// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ---------- DASHBOARD ---------- */
.dashboard-wrapper {
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Take up full width */
}

.dashboard-title {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.btn-logout {
    max-width: 150px;
    width: 100%;
    margin-top: 10px; /* Ensure some space */
}

#collapse-content .card {
    max-width: 100%;
    overflow-x: auto;
}

/* Set a max-height for the instructions and make it scrollable */
.instructions-content {
    max-height: 25vh; /* 50% of the viewport height */
    overflow-y: auto;
}

@media (min-width: 768px) {
    .dashboard-title {
        text-align: left;
    }

    .btn-logout {
        width: auto; /* Auto width for larger screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;IACvC,WAAW,EAAE,uBAAuB;AACxC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB,EAAE,sBAAsB;AAC5C;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA,iEAAiE;AACjE;IACI,gBAAgB,EAAE,+BAA+B;IACjD,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,WAAW,EAAE,kCAAkC;IACnD;AACJ","sourcesContent":["/* ---------- DASHBOARD ---------- */\n.dashboard-wrapper {\n    padding: 2rem;\n    background-color: #f8f9fa;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    width: 100%; /* Take up full width */\n}\n\n.dashboard-title {\n    font-weight: 500;\n    font-size: 2rem;\n    margin-bottom: 1rem;\n    text-align: center;\n}\n\n.btn-logout {\n    max-width: 150px;\n    width: 100%;\n    margin-top: 10px; /* Ensure some space */\n}\n\n#collapse-content .card {\n    max-width: 100%;\n    overflow-x: auto;\n}\n\n/* Set a max-height for the instructions and make it scrollable */\n.instructions-content {\n    max-height: 25vh; /* 50% of the viewport height */\n    overflow-y: auto;\n}\n\n@media (min-width: 768px) {\n    .dashboard-title {\n        text-align: left;\n    }\n\n    .btn-logout {\n        width: auto; /* Auto width for larger screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
