import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './AudioRecorder.css';
import { Button, Toast, ProgressBar, Form, Modal } from 'react-bootstrap';

const AudioRecorder = () => {
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null); // New state variable
    const [errorMessage, setErrorMessage] = useState('');
    const [timer, setTimer] = useState(0);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [transcriptionStatus, setTranscriptionStatus] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [progress, setProgress] = useState(0);
    const [email, setEmail] = useState('quality@doctorschoicetx.com');
    const timerRef = useRef(null);

    const [transcriptionId, setTranscriptionId] = useState(null);
    const [isPolling, setIsPolling] = useState(false);

    const [showCompletionModal, setShowCompletionModal] = useState(false);

    const getSupportedMimeType = () => {
        const possibleTypes = [
            'audio/webm;codecs=opus',
            'audio/ogg;codecs=opus',
            'audio/mp4', // Commonly supported on iOS/Safari
            'audio/wav', // As a last resort if none of the others work
        ];

        for (const type of possibleTypes) {
            if (MediaRecorder.isTypeSupported(type)) {
                return type;
            }
        }
        return '';
    };

    const accessMic = async () => {
        try {
            // Clear uploaded file and audioUrl
            setUploadedFile(null);
            setAudioUrl(null);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setAudioStream(stream);

            const mimeType = getSupportedMimeType();
            if (!mimeType) {
                throw new Error('No supported MIME type found for recording.');
            }
            const options = { mimeType };
            const recorder = new MediaRecorder(stream, options);
            setMediaRecorder(recorder);

            const chunks = [];
            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                }
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: mimeType });
                const url = URL.createObjectURL(blob);
                setAudioBlob(blob);
                setAudioUrl(url);
            };

            recorder.start();
            setRecording(true);
            setErrorMessage('');
            startTimer();
            setProgress(0); // Reset progress when starting a new recording
        } catch (error) {
            setErrorMessage('Error accessing the microphone: ' + error.message);
            setShowToast(true);
        }
    };

    const stopAccessingMic = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            audioStream.getTracks().forEach((track) => track.stop());
            setRecording(false);
            stopTimer();
        }
    };

    const handleClick = () => {
        if (!recording) {
            accessMic();
        } else {
            stopAccessingMic();
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Stop recording if ongoing
            if (recording) {
                stopAccessingMic();
            }
            setUploadedFile(file);
            const url = URL.createObjectURL(file);
            setAudioUrl(url); // Reuse audioUrl to play the uploaded file
            setAudioBlob(null); // Clear recorded audioBlob if any
        }
    };

    const handleSend = async () => {
        const fileToSend = uploadedFile || audioBlob;

        if (fileToSend) {
            if (!email) {
                setErrorMessage('Please enter an email address.');
                setShowToast(true);
                return;
            }
            try {
                setIsTranscribing(true);
                setTranscriptionStatus('Uploading and processing audio...');
                setProgress(20);

                const formData = new FormData();
                if (audioBlob) {
                    const extension = audioBlob.type.includes('ogg') ? 'ogg' : 'webm';
                    formData.append('audio', audioBlob, `recording.${extension}`);
                } else {
                    formData.append('audio', fileToSend);
                }

                formData.append('email', email); // Include email if required by the new endpoint
                const token = localStorage.getItem('authToken');

                const response = await axios.post('https://transcribe_api.doctorschoicetx.com/api/submit', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `${token}`,
                    },
                });

                const { transcriptionId } = response.data;
                setTranscriptionId(transcriptionId); // Store transcriptionId in state

                setTranscriptionStatus('Audio uploaded. Waiting for transcription...');
                setProgress(40);
                pollTranscriptionResult(transcriptionId); // Start polling
            } catch (error) {
                setErrorMessage('Error uploading audio: ' + error.message);
                setShowToast(true);
                setIsTranscribing(false);
                setProgress(0);
            }
        } else {
            setErrorMessage('No audio recorded or uploaded to send');
            setShowToast(true);
        }
    };

    const pollTranscriptionResult = async (id) => {
        setIsPolling(true);
        let shouldContinue = true;

        while (shouldContinue) {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`https://transcribe_api.doctorschoicetx.com/api/status/${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });

                const { status } = response.data;

                if (status === 'completed') {
                    setTranscriptionStatus('Transcription completed. Check your email for the transcription.');
                    setIsTranscribing(false);
                    setProgress(100);
                    setIsPolling(false);
                    shouldContinue = false;
                    setTranscriptionId(null);

                    setShowCompletionModal(true);
                } else if (status === 'failed') {
                    setErrorMessage('Transcription failed.');
                    setShowToast(true);
                    setIsTranscribing(false);
                    setProgress(0);
                    setIsPolling(false);
                    shouldContinue = false;
                    setTranscriptionId(null);
                } else {
                    setTranscriptionStatus('Transcription in progress...');
                    setProgress((prevProgress) => Math.min(prevProgress + 5, 80));
                    await new Promise((resolve) => setTimeout(resolve, 5000));
                }
            } catch (error) {
                setErrorMessage('Error fetching transcription result: ' + error.message);
                setShowToast(true);
                setIsTranscribing(false);
                setProgress(0);
                setIsPolling(false);
                shouldContinue = false;
            }
        }
    };

    const checkTranscriptionStatus = async () => {
        if (!transcriptionId) {
            setErrorMessage('No transcription in progress.');
            setShowToast(true);
            return;
        }
        try {
            setIsPolling(true);
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`https://transcribe_api.doctorschoicetx.com/api/status/${transcriptionId}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });

            const { status } = response.data;

            if (status === 'completed') {
                setTranscriptionStatus('Your email has been sent successfully.');
                setIsTranscribing(false);
                setProgress(100);
                setIsPolling(false);
                setTranscriptionId(null);
            } else if (status === 'failed') {
                setErrorMessage('Transcription failed.');
                setShowToast(true);
                setIsTranscribing(false);
                setProgress(0);
                setIsPolling(false);
                setTranscriptionId(null);
            } else {
                setTranscriptionStatus('Transcription in progress...');
                setProgress((prevProgress) => Math.min(prevProgress + 5, 80));
                setIsPolling(false);
            }
        } catch (error) {
            setErrorMessage('Error checking transcription status: ' + error.message);
            setShowToast(true);
            setIsTranscribing(false);
            setProgress(0);
            setIsPolling(false);
        }
    };

    // Function to reset the component state
    const resetState = () => {
        setRecording(false);
        setMediaRecorder(null);
        setAudioStream(null);
        setAudioUrl(null);
        setAudioBlob(null);
        setUploadedFile(null); // Clear uploaded file
        setErrorMessage('');
        setTimer(0);
        setIsTranscribing(false);
        setTranscriptionStatus('');
        setShowToast(false);
        setProgress(0);
        setTranscriptionId(null);
        setIsPolling(false);
        // Optionally reset the email field as well
        // setEmail('support@quikbox.com');
    };

    // Function to handle modal close event
    const handleModalClose = () => {
        setShowCompletionModal(false);
        //resetState();
        window.location.reload(); // Refresh the page
    };

    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
    };

    const stopTimer = () => {
        clearInterval(timerRef.current);
        setTimer(0);
    };

    useEffect(() => {
        return () => clearInterval(timerRef.current);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    // Handle visibility change to check status when user returns to the app
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden && transcriptionId && !isPolling && isTranscribing) {
                // Page has become visible again, check the status
                checkTranscriptionStatus();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [transcriptionId, isPolling, isTranscribing]);

    return (
        <div className="audio-recorder-wrapper">
            {/* Toast Popup for error messages */}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
            >
                <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>

            <Button onClick={handleClick} disabled={isTranscribing || isPolling || uploadedFile} className="mb-3 w-100">
                {recording ? 'Stop Recording' : 'Start Recording'}
            </Button>

            {recording && <p>Recording Time: {formatTime(timer)}</p>}

            {/* File Upload Input */}
            <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Upload Audio File</Form.Label>
                <Form.Control
                    type="file"
                    accept="*/*"
                    onChange={handleFileUpload}
                    disabled={isTranscribing || isPolling || recording}
                />
            </Form.Group>

            {audioUrl && (
                <div className="mt-3">
                    <h3>{uploadedFile ? 'Uploaded Audio' : 'Recorded Audio'}</h3>
                    <audio src={audioUrl} controls className="w-100" />

                    {/* Email Input Field */}
                    <Form.Group controlId="formEmail" className="mt-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isTranscribing || isPolling}
                        />
                    </Form.Group>

                    <Button
                        onClick={handleSend}
                        disabled={recording || isTranscribing || isPolling}
                        className="w-100 mt-3"
                    >
                        Convert Audio and Send Email
                    </Button>
                </div>
            )}

            {progress > 0 && (
                <div className="mt-3">
                    <ProgressBar now={progress} label={`${progress}%`} />
                    {transcriptionStatus && <p className="mt-2">{transcriptionStatus}</p>}
                </div>
            )}

            {/* Completion Modal */}
            <Modal show={showCompletionModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Transcription Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your transcription is complete and has been sent to your email.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AudioRecorder;
